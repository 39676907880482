import { LayoutStateMasterDataContext } from 'master-data/contexts/LayoutMasterDataProvider/LayoutMasterDataProvider'
import { useContext } from 'react'
import type { MasterData } from 'types/masterData'

/** @throws {Error} If useMasterData is used without MasterDataProvider */
export const useMasterData = (): MasterData => {
	const masterData = useContext(LayoutStateMasterDataContext)
	if (!masterData) {
		throw new Error(
			'useMasterData must be used within a LayoutMasterDataProvider and initPage must be called before useMasterData'
		)
	}
	return masterData
}
