import { type ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers'
import { Device } from 'types/masterData'

export const getDeviceFromHeaders = (headers: () => ReadonlyHeaders) => {
	const headersList = headers?.()
	const isDesktop = !!headersList?.get('x-is-desktop')

	return {
		isDesktop,
		isMobile: !isDesktop,
		device: isDesktop ? Device.Desktop : Device.Mobile,
	}
}
