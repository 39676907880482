'use client'

import type { MasterDataProviderProps } from 'master-data/types'
import { usePathname, useSearchParams } from 'next/navigation'
import {
	type Dispatch,
	type SetStateAction,
	createContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import type { MasterData } from 'types/masterData'

interface LayoutSetStateMasterDataContextType {
	handleUpdateMasterData: (newMasterdata: MasterData) => void
}

export interface LayoutMasterDataIsReadyContextValue {
	masterDataIsReady: boolean
	setMasterDataIsReady: Dispatch<SetStateAction<boolean>>
}

export const LayoutStateMasterDataContext = createContext<
	MasterData | undefined
>(undefined)

export const LayoutSetStateMasterDataContext = createContext<
	LayoutSetStateMasterDataContextType | undefined
>(undefined)

export const LayoutMasterDataIsReadyContext = createContext<
	LayoutMasterDataIsReadyContextValue | undefined
>(undefined)

export function LayoutMasterDataProvider({
	masterData: initialMasterData,
	children,
}: MasterDataProviderProps) {
	const [masterData, setMasterData] = useState(initialMasterData)
	const [masterDataIsReady, setMasterDataIsReady] = useState(false)
	const pathname = usePathname()
	const searchParams = useSearchParams()

	const handleUpdateMasterData = (newMasterData: MasterData): void => {
		setMasterData(newMasterData)
		setMasterDataIsReady(true)
	}

	useEffect(() => {
		if (masterDataIsReady) {
			setMasterDataIsReady(false)
		}
	}, [pathname, searchParams])

	const value = useMemo(() => masterData, [masterData])

	const setValue = useMemo(() => ({ handleUpdateMasterData }), [setMasterData])

	const masterDataIsReadyValue = useMemo(
		() => ({ masterDataIsReady, setMasterDataIsReady }),
		[masterDataIsReady, setMasterDataIsReady]
	)

	return (
		<LayoutStateMasterDataContext.Provider value={value}>
			<LayoutSetStateMasterDataContext.Provider value={setValue}>
				<LayoutMasterDataIsReadyContext.Provider value={masterDataIsReadyValue}>
					{children}
				</LayoutMasterDataIsReadyContext.Provider>
			</LayoutSetStateMasterDataContext.Provider>
		</LayoutStateMasterDataContext.Provider>
	)
}
