import { mapLabelKeysToBrands } from 'country-configuration/utils/brands/mapLabelKeysToBrands'
import { type ReadonlyHeaders } from 'next/dist/server/web/spec-extension/adapters/headers'
import type { PageType } from 'types/analytics'
import type { Brand } from 'types/brands'
import { Channel, type MasterData, Platform } from 'types/masterData'
import type { CustomDomainData } from 'types/masterData/domainData/domainData'
import { env } from 'utils/envManager'
import { getABTestFromHeaders } from 'utils/getABTestFromHeaders/getABTestFromHeaders'
import { getCustomDateFromHeaders } from 'utils/getCustomDateFromHeaders/getCustomDateFromHeaders'
import { getDeviceFromHeaders } from 'utils/getDeviceFromHeaders/getDeviceFromHeaders'
import { getRegionFromHeaders } from 'utils/getRegionFromHeaders/getRegionFromHeaders'
import { getTestFromHeaders } from 'utils/getTestFromHeaders/getTestFromHeaders'

import type { SetMasterDataParams } from './types'

const initialState: Partial<MasterData> = {
	isApp: false,
	brand: null,
	h1LogoKey: undefined,
	custom: null,
}

let masterData: Partial<MasterData> = initialState

export const resetMasterData = () => {
	masterData = initialState
}

export const setMasterData = ({
	pageType,
	h1LogoKey,
	headers,
}: SetMasterDataParams): void => {
	const headersList = headers?.()
	const isCicd = !!headersList?.get('x-is-lighthouse')
	const isQa = !!headersList?.get('x-qa')
	const isRevalidation = !!headersList?.get('if-modified-since')
	// ios and android only on checkout
	const platform = (headersList?.get('x-platform') as Platform) ?? Platform.Web
	const dev = headersList?.get('x-dev')
	const referer = headersList?.get('referer')
	const userAgent = headersList?.get('user-agent')
	const url = headersList?.get('x-url') as string

	const { isDesktop, isMobile, device } = getDeviceFromHeaders(
		headers as () => ReadonlyHeaders
	)
	const { test } = getTestFromHeaders(headers as () => ReadonlyHeaders)
	const { customDate } = getCustomDateFromHeaders(
		headers as () => ReadonlyHeaders
	)
	const { region } = getRegionFromHeaders(headers as () => ReadonlyHeaders)

	const countryConfHeader = headersList?.get(
		'x-country-configuration'
	) as string

	const { abTestPage, abTestLayout } = getABTestFromHeaders(
		headers as () => ReadonlyHeaders
	)

	// Need this validation because error/redirect pages refresh all except middleware
	if (countryConfHeader) {
		const countryConfiguration = JSON.parse(
			decodeURIComponent(countryConfHeader)
		)

		const {
			additionalCurrencies,
			brands,
			countryISO,
			countryName,
			currency,
			hasDiscount,
			hasOnlineProducts,
			hasOnlineSale,
			hasPriceHistory,
			isDefaultLanguage,
			isDiscountSeason,
			isLoyalty,
			isMultiLanguage,
			isRtl,
			kcSafetyGenderIds,
			languageISO,
			languageName,
			locale,
			loyalty,
			mangoISO,
			mngLogo,
			refund,
			shippingInfo,
			shoppingCart,
			shouldShowDiscountRate,
			shouldShowImportData,
			shouldShowReleasesMicrofibers,
			warehouse,
		} = countryConfiguration

		masterData = {
			...masterData,
			abTestPage,
			abTestLayout,
			platform,
			channel: env.NEXT_PUBLIC_CHANNEL,
			isShop: env.NEXT_PUBLIC_CHANNEL === Channel.Shop,
			isApp: env.NEXT_PUBLIC_IS_APP === 'true',
			environment: env.NEXT_PUBLIC_ENV,
			brand: null,
			device,
			isMobile,
			isDesktop,
			isRevalidation,
			pageType,
			customDate,
			h1LogoKey,
			userAgent,
			isCicd,
			isQa,
			dev,
			url,
			referer,
			region,
			test,
			country: {
				// TODO: temporary hack to map proper keys till country config service returns the appropiate ones.
				brands: mapLabelKeysToBrands(brands),
				additionalCurrencies,
				countryISO,
				countryName,
				currency,
				hasDiscount,
				hasOnlineProducts,
				hasOnlineSale,
				hasPriceHistory,
				isDefaultLanguage,
				isDiscountSeason,
				isLoyalty,
				isMultiLanguage,
				isRtl,
				kcSafetyGenderIds,
				languageISO,
				languageName,
				locale,
				loyalty,
				mangoISO,
				mngLogo,
				refund,
				shippingInfo,
				shoppingCart,
				shouldShowDiscountRate,
				shouldShowImportData,
				shouldShowReleasesMicrofibers,
				warehouse,
			},
		}
	}
}

/** @throws {Error} If master data is not initialized */
export const getMasterData = (): MasterData => {
	if (!masterData.country) {
		throw new Error(
			'[MasterData] Country info is not set. Did you forget calling setMasterData before? You should call setMasterData with parameters at least once.'
		)
	}
	if (!masterData.pageType) {
		throw new Error(
			'[MasterData] Page type is not set. Did you forget calling setPageType before?'
		)
	}
	return masterData as MasterData
}

export const setPageType = (pageType: PageType): void => {
	masterData.pageType = pageType
}

export const setH1LogoKey = (h1LogoKey: string): void => {
	masterData.h1LogoKey = h1LogoKey
}

export const setBrand = (brandId: Brand['id'] | null): void => {
	masterData.brand = brandId
}

export const setCustom = (custom: CustomDomainData): void => {
	masterData.custom = custom
}
