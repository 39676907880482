import { getMasterData } from 'master-data'

import {
	ErrorManager,
	type ErrorManagerClass,
} from '../../components/ErrorManager/ErrorManager'
import type { SendErrorParams } from '../../types/ErrorsLogs'

export function sendError(
	{ domain, custom, message }: SendErrorParams,
	options?: ErrorOptions
): ErrorManager {
	const {
		brand,
		isRevalidation,
		country: { countryISO, languageISO },
		device,
		userAgent,
		pageType,
		referer,
		url,
	} = getMasterData()
	const error: ErrorManagerClass = {
		country: countryISO,
		language: languageISO,
		brand,
		isRevalidation,
		custom,
		device,
		domain,
		userAgent,
		message,
		pageType,
		referer,
		url,
	}

	return new ErrorManager(error, options)
}
