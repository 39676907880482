import type { Domains } from '../../constants/domains'
import { Types } from '../../constants/types'

export interface ErrorManagerClass {
	domain: Domains
	country: string
	language: string
	message: string
	isRevalidation?: boolean
	brand?: string | null
	custom?: unknown
	device?: string
	userAgent?: string | null
	pageType?: string
	referer?: string | null
	url?: string | null
}

export class ErrorManager extends Error {
	domain: Domains
	country: string
	language: string
	isRevalidation?: boolean
	brand?: string | null
	custom?: unknown
	device?: string
	pageType?: string
	referer?: string | null
	type: Types
	url?: string | null

	constructor(
		{
			brand,
			isRevalidation,
			country,
			custom,
			device,
			domain,
			language,
			message,
			pageType,
			referer,
			url,
		}: ErrorManagerClass,
		options?: ErrorOptions
	) {
		super(message, options)

		this.brand = brand
		this.isRevalidation = isRevalidation
		this.country = country
		this.custom = custom
		this.device = device
		this.domain = domain
		this.language = language
		this.name = '[Genesis]'
		this.pageType = pageType
		this.referer = referer
		this.type = Types.Error
		this.url = url
	}
}
