'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { type AnchorLinkProps, Link, type NextLinkProps } from '../Link'

type ClientNextLinkProps = Omit<NextLinkProps, 'masterData'>
type ClientAnchorLinkProps = Omit<AnchorLinkProps, 'masterData'>

export type ClientLinkProps = ClientNextLinkProps | ClientAnchorLinkProps

export function ClientLink(props: ClientLinkProps): React.ReactNode {
	const masterData = useMasterData()

	return <Link {...props} masterData={masterData} />
}
